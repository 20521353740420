<script setup lang="ts">
import groupFields from './form/signupForm'
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'
import type { MutationType } from '../BaseForm/types'
import type { Auth } from '~/types/types'

defineOptions({
  inheritAttrs: false,
})
const props = defineProps<{
  inputEmail: string
  inputToken: string
  userFromProvider?: {
    email: string
    firstName: string
    lastName: string
  }
}>()
const emits = defineEmits<{
  'on-go-back': [{ navigation: string }]
  'handle-redirect': []
}>()

const { $api, $lcServicesAuth } = useNuxtApp()
const { authController, authPresenter } = $lcServicesAuth
const { login, credentials } = useAuth()
const localePath = useLocalePath()
const { t } = useI18n()
const { trackEvent } = useTracking()

const response =
  authPresenter.vm.signUpResponse || authPresenter.vm.verifyTokenResponse
const updatedAccount = computed(() => {
  const signUpResponse = authPresenter?.vm?.signUpResponse

  return (
    signUpResponse?.firstName &&
    signUpResponse?.lastName &&
    signUpResponse?.email
  )
})
const { btnPrimary } = useButtons()
btnPrimary.text = computed(() =>
  updatedAccount.value ? t('account.createAccount') : t('auth.createAccount'),
)
const title = computed(() =>
  updatedAccount.value ? t('account.completeProfile') : t('auth.createAccount'),
)

const groupFieldsForm = reactive(groupFields())
const initialData = reactive({
  civility: response?.civility || '',
  firstName:
    response?.firstName ||
    props.userFromProvider?.firstName ||
    credentials.value?.given_name ||
    '',
  lastName:
    response?.lastName ||
    props.userFromProvider?.lastName ||
    credentials.value?.name ||
    '',
  newsletter: false,
  phone: response?.phone || '',
  termsOfServiceSigned: response?.termsOfServiceSigned || false,
})

const configForm = reactive({
  fields: {
    newsletter: {
      attr: {
        label: '',
        labelHtml: computed(
          () =>
            `text:#{global.subscribe_newsletter},link:#{global.privacyPolicyWithPoint}[href_blank:${localePath(
              'legal-privacyPolicy',
            )}][class:font-md text-primary-800]`,
        ),
      },
    },
    termsOfServiceSigned: {
      attr: {
        label: '',
        labelHtml: computed(
          () =>
            `text:#{account.agree_on_terms1},link:#{global.termsAndConditionsWithPoint}[href_blank:${localePath(
              'legal-termsOfUse',
            )}][class:font-md text-primary-800]`,
        ),
        hidden: response?.termsOfServiceSigned,
      },
    },
  },
})

const handleResponse = async (user: Auth['user'], msg: string) => {
  if (msg === 'signUp.accountSuccessfullCreated') {
    await login(user)
    // tracking
    trackEvent({
      event: 'sign_up',
      label: { source: 'signup funnel' },
    })

    if (initialData.newsletter) {
      trackEvent({
        event: 'newsletter_subscription',
        label: 'sign_up',
        post_alert: false,
      })
    }

    emits('handle-redirect')
  }
}

const mutation: MutationType<typeof initialData> = (input) => ({
  request: async () => {
    await authController.signUp(
      {
        user: {
          civility: initialData.civility,
          email:
            props.inputEmail ||
            props.userFromProvider?.email ||
            credentials.value?.email ||
            '',
          first_name:
            initialData.firstName ||
            props.userFromProvider?.firstName ||
            credentials.value?.given_name ||
            '',
          last_name:
            initialData.lastName ||
            props.userFromProvider?.lastName ||
            credentials.value?.name ||
            '',
          newsletter: initialData.newsletter,
          phone: initialData.phone,
          signup_token: props.inputToken,
          terms_of_service_signed: initialData.termsOfServiceSigned,
        },
      },
      response!,
    )
    if (authPresenter.vm.errors.length) {
      return Promise.reject(authPresenter.vm.errors)
    }

    const { data: userCreated } = await $api.v1.auth.validateToken()
    return { ...authPresenter.vm, userCreated }
  },
  input,
})

const onSubmit: () => SendMutationType<{
  userCreated: Auth['user']
  msg: string
}>['onSubmit'] = () => ({
  success: ({ userCreated, msg }) => {
    handleResponse(userCreated, msg)
  },
  error: (err, setFieldError) => {
    const [firstError] = Array.isArray(err?.errors) ? err.errors : [err.errors]
    if (firstError?.fieldName === 'phone') {
      setFieldError('phone', t('error.taken', { label: t('field.phone') }))
    }
  },
})
</script>

<template>
  <div class="w-full">
    <div>
      <button
        v-if="!updatedAccount"
        class="flex"
        type="button"
        @click="emits('on-go-back', { navigation: 'Token' })"
      >
        <BaseIcon
          class="mb-4"
          :name="$device.isMobile ? 'navArrowLeft' : 'arrowLeft'"
        />
        <span v-if="$device.isMobile" class="ml-1 font-medium">
          {{ $t('account.back') }}
        </span>
      </button>
      <div class="mb-10">
        <h1 class="mt-8 text-xl md:text-5xl">
          {{ title }}
        </h1>
        <p>{{ inputEmail || credentials?.email }}</p>
      </div>

      <BaseForm
        id-form="signupForm"
        :active-error-scroll-to="false"
        :active-modal-errors="false"
        :active-modal-save-or-quit="false"
        :button-primary="btnPrimary"
        :config-form="configForm"
        :group-fields="groupFieldsForm"
        :initial-data="initialData"
        :mutation="mutation"
        :on-submit="onSubmit"
        container-button-class="mt-4"
      />
    </div>
  </div>
</template>
